import { h, render } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import styles from './SearchModal.styles.css';
import ShadowWrapper from '../ShadowWrapper/ShadowWrapper.jsx';
import SearchHit from '../SearchHit/SearchHit.jsx';
import { track } from '../../lib/analytics.js';

const SearchModal = ({ isOpen, onClose }) => {
  const [algoliaReady, setAlgoliaReady] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [unifiedResults, setUnifiedResults] = useState([]);
  const [rockResults, setRockResults] = useState([]);
  const [jekyllResults, setJekyllResults] = useState([]);
  const [_query, setQuery] = useState(null);

  const queryRef = useRef(null);
  const searchBoxRef = useRef(null);
  const hitsRef = useRef(null);
  const debounceTimeoutsRef = useRef({});

  const getImageUrl = (image) => {
    return typeof image === 'string' ? image : image && image.url ? image.url : '';
  };

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
      document.head.appendChild(script);
    });
  };

  const handleSubmit = (query) => {
    if (query) {
      window.location.href = `${process.env.NEXT_PUBLIC_CRDS_SITE_DOMAIN}search?query=${encodeURIComponent(query)}`;
    }
  };

  useEffect(() => {
    const loadAlgoliaScripts = async () => {
      try {
        await loadScript(
          'https://cdn.jsdelivr.net/npm/algoliasearch@4/dist/algoliasearch-lite.umd.js'
        );
        await loadScript('https://cdn.jsdelivr.net/npm/instantsearch.js@4');
        setAlgoliaReady(true);
      } catch (error) {
        console.error(error);
      }
    };

    loadAlgoliaScripts();
  }, []);

  useEffect(() => {
    if (isOpen && algoliaReady) {
      document.body.style.overflow = 'hidden';

      const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
          onClose();
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      const searchClient = window.algoliasearch(
        process.env.ALGOLIA_APP_ID,
        process.env.ALGOLIA_API_KEY
      );

      const search = window.instantsearch({
        indexName: process.env.CRDS_ENV === 'prod' ? 'unified_prod' : 'unified_demo',
        searchClient,
        searchFunction(helper) {
          const query = helper.state.query;
          if (query) {
            helper.search();
            setQuery(query);
            queryRef.current = query;
            setResultsVisible(true);
          } else {
            setResultsVisible(false);
            setUnifiedResults([]);
            setRockResults([]);
            setJekyllResults([]);
            setQuery(null);
            queryRef.current = null;
            if (hitsRef.current) {
              hitsRef.current.innerHTML = '';
            }
          }
        }
      });

      search.use(
        window.instantsearch.middlewares.createInsightsMiddleware({
          insightsClient: window.aa,
          onEvent: (event, aa) => {
            const { widgetType, eventType, payload } = event;
            if (eventType === 'view' && widgetType === 'ais.hits') {
              const indexKey = payload.index;
              if (debounceTimeoutsRef.current[indexKey]) {
                clearTimeout(debounceTimeoutsRef.current[indexKey]);
              }
              debounceTimeoutsRef.current[indexKey] = setTimeout(() => {
                track('QuerySearched', {
                  queryID: payload.queryID,
                  objectIDs: payload.objectIDs,
                  positions: payload.positions,
                  index: payload.index,
                  query: queryRef.current,
                  source: 'Modal'
                });
                debounceTimeoutsRef.current[indexKey] = null;
              }, 1000);
            }
            if (event.insightsMethod) {
              aa(event.insightsMethod, payload);
            }
          }
        })
      );

      search.addWidgets([
        window.instantsearch.widgets.configure({
          hitsPerPage: 16,
          filters:
            'searchExcluded:false AND (contentType:"author" OR contentType:"location" OR contentType:"category" OR distribution_channels.site:"www.crossroads.net")'
        }),
        window.instantsearch.widgets.searchBox({
          container: searchBoxRef.current,
          placeholder: 'Search service times, the latest message, groups and more...',
          showReset: true,
          showSubmit: true,
          showLoadingIndicator: false,
          queryHook(query, search) {
            if (query) {
              search(query);
            } else {
              setResultsVisible(false);
              setUnifiedResults([]);
              setRockResults([]);
              setJekyllResults([]);
              setQuery(null);
              queryRef.current = null;
              if (hitsRef.current) {
                hitsRef.current.innerHTML = '';
              }
            }
          }
        }),
        window.instantsearch.widgets.hits({
          container: hitsRef.current,
          transformItems(items, { results }) {
            if (results.query === '') return [];
            const newItems = items.slice(0, 16);
            setUnifiedResults(newItems);
            return newItems;
          },
          templates: {
            empty: ({ query }, { html }) => {
              return html`<div className="no-results">
                Whoops, we can't find any results matching <strong>${query}</strong>.
              </div>`;
            },
            item: (hit) => {
              const { title, name, fullName, description, contentType, url, image, author } = hit;
              const imgUrl = getImageUrl(image);
              const authorName = Array.isArray(author) && author.length > 0 ? author[0].name : '';
              return `
                <search-hit
                  theme='modal'
                  title='${title || fullName || name}'
                  body='${description}'
                  ${imgUrl ? `image='${imgUrl}'` : ''}
                  category='${contentType}'
                  url='${url}'
                  ${authorName ? `author='${authorName}'` : ''}
                ></search-hit>
              `;
            }
          }
        }),
        window.instantsearch.widgets
          .index({
            indexName: process.env.CRDS_ENV === 'prod' ? 'rock_prod' : 'rock_dev'
          })
          .addWidgets([
            window.instantsearch.widgets.configure({
              hitsPerPage: 8,
              filters: "distribution_channels.site:'www.crossroads.net' AND searchExcluded:false"
            }),
            window.instantsearch.widgets.hits({
              container: hitsRef.current,
              transformItems(items, { results }) {
                if (results.query === '') return [];
                const newItems = items.slice(0, 8);
                setRockResults(newItems);
                return newItems;
              },
              templates: {
                item: (hit) => {
                  const { title, description, contentType, url, image } = hit;
                  const imgUrl = getImageUrl(image);
                  return `
                <search-hit
                  theme='modal'
                  title='${title}'
                  body='${description}'
                  ${imgUrl ? `image='${imgUrl}'` : ''}
                  category='${contentType}'
                  url='${url}'
                ></search-hit>
              `;
                }
              }
            })
          ]),
        window.instantsearch.widgets
          .index({
            indexName: process.env.CRDS_ENV === 'prod' ? 'crds_net_static' : 'demo_crds_net_static'
          })
          .addWidgets([
            window.instantsearch.widgets.configure({
              hitsPerPage: 8,
              filters: "distribution_channels.site:'www.crossroads.net' AND searchExcluded:false"
            }),
            window.instantsearch.widgets.hits({
              container: hitsRef.current,
              transformItems(items, { results }) {
                if (results.query === '') return [];
                const newItems = items.slice(0, 8);
                setJekyllResults(newItems);
                return newItems;
              },
              templates: {
                item: (hit) => {
                  const { title, description, contentType, url, image } = hit;
                  const imgUrl = getImageUrl(image);
                  return `
                <search-hit
                  theme='modal'
                  title='${title}'
                  body='${description}'
                  ${imgUrl ? `image='${imgUrl}'` : ''}
                  category='${contentType}'
                  url='${url}'
                ></search-hit>
              `;
                }
              }
            })
          ])
      ]);

      search.on('render', () => {
        const searchBoxElement = searchBoxRef.current.querySelector('.ais-SearchBox-form');
        if (searchBoxElement) {
          searchBoxElement.onsubmit = (e) => {
            e.preventDefault();
            const query = searchBoxElement.querySelector('.ais-SearchBox-input').value;
            handleSubmit(query);
          };
        }
      });

      search.start();

      if (searchBoxRef.current) {
        const searchBoxInput = searchBoxRef.current.querySelector('.ais-SearchBox-input');
        if (searchBoxInput) {
          searchBoxInput.focus();
        }
      }

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        search.dispose();
        document.body.style.overflow = '';
        Object.values(debounceTimeoutsRef.current).forEach((timeout) => {
          if (timeout) clearTimeout(timeout);
        });
      };
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen, algoliaReady, onClose]);

  useEffect(() => {
    if (!isOpen) {
      setUnifiedResults([]);
      setRockResults([]);
      setJekyllResults([]);
      setResultsVisible(false);
      setQuery(null);
      queryRef.current = null;
      if (hitsRef.current) {
        hitsRef.current.innerHTML = '';
      }
    }
  }, [isOpen]);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  const renderSearchHit = (hit) => {
    const { title, name, fullName, description, contentType, url, image, author } = hit;
    const imgUrl = getImageUrl(image);
    const authorName = Array.isArray(author) && author.length > 0 ? author[0].name : undefined;
    return (
      <SearchHit
        theme="modal"
        title={title || name || fullName}
        body={description}
        image={imgUrl}
        category={contentType}
        url={url}
        author={authorName}
      ></SearchHit>
    );
  };

  return (
    algoliaReady && (
      <ShadowWrapper styles={styles}>
        <div className="overlay" onClick={handleOverlayClick}>
          <div className="modal">
            <div
              ref={searchBoxRef}
              className={`input-container ${resultsVisible ? 'results-visible' : ''}`}
            >
              <button className="cancel" onClick={onClose}>
                Cancel
              </button>
            </div>
            {resultsVisible && <div className="results-banner">RESULTS</div>}
            <div className="results-container">
              <div ref={hitsRef} style="display:none;"></div>
              <div>{rockResults.length > 0 && rockResults.map(renderSearchHit)}</div>
              <div>{jekyllResults.length > 0 && jekyllResults.map(renderSearchHit)}</div>
              <div>{unifiedResults.length > 0 && unifiedResults.map(renderSearchHit)}</div>
              {unifiedResults.length === 0 &&
                rockResults.length === 0 &&
                jekyllResults.length === 0 &&
                _query !== null && (
                  <div className="no-results">
                    Whoops, we can't find any results matching <strong>{_query}</strong>.
                  </div>
                )}
            </div>
          </div>
        </div>
      </ShadowWrapper>
    )
  );
};

// Function to mock the modal
window.openSearchModal = () => {
  const container = document.createElement('div');
  document.body.appendChild(container);

  const closeModal = () => {
    render(null, container);
    document.body.removeChild(container);
  };

  render(<SearchModal isOpen={true} onClose={closeModal} />, container);
};

export default SearchModal;
