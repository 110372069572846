import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { DateTime } from 'luxon';
import ShadowWrapper from '../ShadowWrapper/ShadowWrapper.jsx';
import styles from './HeaderNotification.styles.css';

const HeaderNotification = ({ data = {}, user }) => {
  const [alerts, setAlerts] = useState([]);
  const [opened, setOpened] = useState(true);
  const storageKey = 'crds-header-notification-closed';

  const getCurrentURLSlug = () => {
    const currentUrl = window.location.href;
    const urlWithoutDomain = currentUrl.split(window.location.origin)[1];
    return removeTrailingSlash(urlWithoutDomain);
  };

  const removeTrailingSlash = (url) => {
    if (!url) return '';
    if (url.startsWith('/')) {
      url = url.slice(1);
    }
    if (url.endsWith('/')) {
      url = url.slice(0, -1);
    }
    return url;
  };

  const removeQueryParams = (url) => {
    const queryStringIndex = url.indexOf('?');
    if (queryStringIndex !== -1) {
      return url.substring(0, queryStringIndex);
    }
    return url;
  };

  const isDateInRange = (dateStart, dateEnd) => {
    const nowIso = DateTime.local();
    if (!dateStart || isNaN(new Date(dateStart).getTime())) {
      console.error(`Invalid start date: ${dateStart}`);
      return false;
    }
    const startIso = DateTime.fromISO(dateStart);
    if (dateEnd) {
      if (isNaN(new Date(dateEnd).getTime())) {
        console.error(`Invalid end date: ${dateEnd}`);
        return false;
      }
      const endIso = DateTime.fromISO(dateEnd);
      return startIso < nowIso && endIso > nowIso;
    }
    return startIso < nowIso;
  };

  const isAlertVisible = (alert, currentPath, userSiteLocation) => {
    const alertPath = removeTrailingSlash(alert.single_page_alert);
    const hasValidDateRange = isDateInRange(alert.publish_at, alert.unpublish_at);
    const targetAudience = alert.target_audience || [];
    const hasValidTargetAudience =
      targetAudience.includes('Churchwide') || targetAudience.includes(userSiteLocation);
    const hasValidSinglePageAlert =
      alert.single_page_alert && alert.single_page_alert.trim() !== '' && currentPath === alertPath;
    const shouldDisplay =
      hasValidDateRange &&
      hasValidTargetAudience &&
      (hasValidSinglePageAlert || !alert.single_page_alert);
    return shouldDisplay;
  };

  const filterAlerts = () => {
    if (!data.notifications) {
      return [];
    }
    const currentUrlSlug = getCurrentURLSlug();
    const slugWithoutParams = removeQueryParams(currentUrlSlug);
    const userSiteName =
      user?.campus?.Name === 'Anywhere/Online' ? 'Crossroads Church Online' : user?.campus?.Name;
    return data.notifications.filter((alert) =>
      isAlertVisible(alert, slugWithoutParams, userSiteName)
    );
  };

  useEffect(() => {
    setAlerts(filterAlerts());
    const isClosed = localStorage.getItem(storageKey);
    setOpened(!isClosed);
  }, []);

  const handleToggle = () => {
    const newOpened = !opened;
    setOpened(newOpened);
    if (!newOpened) {
      localStorage.setItem(storageKey, JSON.stringify(alerts));
    } else {
      localStorage.removeItem(storageKey);
    }
  };

  const renderAlerts = () => {
    return (
      <div class="expanded-alerts">
        {alerts.map((alert, index) => (
          <div
            class="alert-text center-text"
            key={index}
            dangerouslySetInnerHTML={{ __html: alert.description || alert.message }}
          ></div>
        ))}
      </div>
    );
  };

  const renderAlertsDropdown = () => {
    return (
      <Fragment>
        <div class="top-container" onClick={handleToggle}>
          {alerts.length !== 0 && (
            <button class="alerts">
              <crds-icon class="bell" name="bell-fill" size="16"></crds-icon>
              {alerts.length} alert{alerts.length === 1 ? '' : 's'}
              <span>
                <crds-icon
                  class="expand"
                  name={`chevron-${opened ? 'down' : 'up'}-thin`}
                  size="12"
                ></crds-icon>
              </span>
            </button>
          )}
        </div>
        {alerts.length !== 0 && opened && (
          <div class={`expanded-alerts ${!opened ? 'alerts-hidden' : ''}`}>{renderAlerts()}</div>
        )}
      </Fragment>
    );
  };

  const renderAlertsBanner = () => {
    return <Fragment>{renderAlerts()}</Fragment>;
  };

  if (!alerts) return null;

  return (
    <ShadowWrapper styles={styles}>
      {alerts.length === 1 ? renderAlertsBanner() : renderAlertsDropdown()}
    </ShadowWrapper>
  );
};

export default HeaderNotification;
