import { h, render } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import styles from './GiveModal.styles.css';
import ShadowWrapper from '../ShadowWrapper/ShadowWrapper.jsx';

const GiveModal = ({ isActive, onModalClose }) => {
  const [isRecurring, setIsRecurring] = useState(false);
  const [formUrl, setFormUrl] = useState('');
  const [activeTab, setActiveTab] = useState('one-time');
  const amountRef = useRef(null);
  const recurringTypeRef = useRef(null);
  const fundRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isActive]);

  useEffect(() => {
    if (process.env.CRDS_ENV && process.env.CRDS_ENV === 'demo') {
      setFormUrl('https://sandbox.pushpay.io/g/crossroadsdemo');
    } else {
      setFormUrl('https://pushpay.com/g/crossroads');
    }
  }, []);

  const closeModal = () => {
    document.body.style.overflow = 'auto';
    if (typeof onModalClose === 'function') {
      onModalClose();
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const amount = parseFloat(amountRef.current.value);
    const frequency = isRecurring ? recurringTypeRef.current.value : 'One-time';
    const fund = fundRef.current.value;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'HeaderDonation', amount, frequency, fund });

    event.target.submit();
  };

  const handleGivingTab = (tabName) => {
    setIsRecurring(tabName === 'recurring');
    setActiveTab(tabName);
  };

  return (
    <ShadowWrapper styles={styles}>
      <div className={`modal ${isActive ? 'is-active' : ''}`} tabIndex="-1" onClick={closeModal}>
        <div className="modal-content" onClick={(event) => event.stopPropagation()}>
          <div className="modal-header">
            <button type="button" className="modal-close" onClick={closeModal}>
              ×
            </button>
          </div>
          <div className="modal-body">
            <form id="pp-embedded-form" action={formUrl} method="get" onSubmit={handleFormSubmit}>
              <input type="hidden" name="src" value="emb" />
              <div id="pp-amountBox">
                <label htmlFor="pp-amount">
                  I'd like to give <i className="pp-required">*</i>
                </label>
                <span className="pp-amountPrefix">$</span>
                <input
                  ref={amountRef}
                  required
                  id="pp-amount"
                  type="number"
                  name="a"
                  placeholder="0.00"
                  step="0.01"
                  min="1"
                  max="100000"
                />
              </div>
              <div id="pp-tabContainer">
                <button
                  type="button"
                  id="pp-tabOneTime"
                  className={activeTab === 'one-time' ? 'pp-active' : ''}
                  onClick={() => handleGivingTab('one-time')}
                >
                  One time
                </button>
                <button
                  type="button"
                  id="pp-tabRecurring"
                  className={activeTab === 'recurring' ? 'pp-active' : ''}
                  onClick={() => handleGivingTab('recurring')}
                >
                  Recurring
                </button>
              </div>
              {activeTab === 'one-time' ? (
                <div id="pp-oneTime">
                  <input type="hidden" name="r" id="pp-giveOnce" value="one" />
                </div>
              ) : null}
              {activeTab === 'recurring' ? (
                <div id="pp-recurring">
                  <label htmlFor="pp-recurringType">
                    Frequency <i className="pp-required">*</i>
                  </label>
                  <select id="pp-recurringType" ref={recurringTypeRef} name="r">
                    <option value="Weekly">Every week</option>
                    <option value="Fortnightly">Every 2 weeks</option>
                    <option value="Monthly">Every month</option>
                    <option value="FirstAndFifteenth">1st and 15th monthly</option>
                    <option value="Quarterly">Every 3 months</option>
                    <option value="SemiYearly">Every 6 months</option>
                    <option value="Yearly">Every year</option>
                  </select>
                  <input type="hidden" name="ret" id="pp-endType" disabled value="never" />
                </div>
              ) : null}
              <div>
                <label htmlFor="pp-fundInput">
                  Towards this fund <i className="pp-required">*</i>
                </label>
                <select ref={fundRef} id="pp-fundInput" name="fnd">
                  <option value="Tithes & Contributions">Tithes & Contributions</option>
                  <option value="Dayton Building">Dayton Building</option>
                </select>
              </div>
              <input
                type="submit"
                id="pp-submitButton"
                value={isRecurring ? 'Setup Recurring Gift' : 'Next'}
              />
            </form>
          </div>
        </div>
      </div>
    </ShadowWrapper>
  );
};

// Function to mock the modal
window.openGiveModal = () => {
  const container = document.createElement('div');
  document.body.appendChild(container);

  const closeModal = () => {
    render(null, container);
    document.body.removeChild(container);
  };

  render(<GiveModal isActive={true} onModalClose={closeModal} />, container);
};

export default GiveModal;
