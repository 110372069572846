import { v4 as uuidv4 } from 'uuid';
import { AuthProvider } from '../components/SharedHeader/authProvider.js';

export const track = async (eventName, properties = {}) => {
  if (!window.analytics) {
    console.warn('Segment not initialized. Call initSegment() first.');
    return;
  }

  const user = AuthProvider.getUser();
  const isAuthenticated = AuthProvider.getIsAuthenticated();
  const anonymousId = uuidv4();

  window.analytics.track(eventName, {
    anonymousId: anonymousId,
    authenticatedUserFlag: isAuthenticated,
    email: user?.user?.Email,
    userId: user?.user?.Id,
    properties: properties
  });
};
