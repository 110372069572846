import { h } from 'preact';
import register from 'preact-custom-element';
import './styles/fonts.css';
import './styles/global-styles.css';
import Button from './components/Button/Button.jsx';
import SearchModal from './components/SearchModal/SearchModal.jsx';
import SearchHit from './components/SearchHit/SearchHit.jsx';
import SharedHeader from './components/SharedHeader/SharedHeader.jsx';
import SharedFooter from './components/SharedFooter/SharedFooter.jsx';
import TenXctaButton from './components/TenXctaButton/TenXctaButton.jsx';
import GiveModal from './components/GiveModal/GiveModal.jsx';
import HeaderNotification from './components/HeaderNotification/HeaderNotification.jsx';

// Initialize Segment **before** registering components
(function () {
  var analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize) {
    if (analytics.invoked) {
      window.console && console.error && console.error('Segment snippet included twice.');
    } else {
      analytics.invoked = !0;
      analytics.methods = [
        'track',
        'identify',
        'page',
        'screen',
        'group',
        'trackLink',
        'trackForm',
        'reset',
        'log',
        'debug',
        'ready',
        'alias',
        'call',
        'once',
        'off',
        'on'
      ];
      analytics.factory = function (t) {
        return function () {
          var e = Array.prototype.slice.call(arguments);
          e.unshift(t);
          analytics.push(e);
          return analytics;
        };
      };
      for (var i = 0; i < analytics.methods.length; i++) {
        var key = analytics.methods[i];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (t) {
        var e = document.createElement('script');
        e.type = 'text/javascript';
        e.async = !0;
        e.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';
        var n = document.getElementsByTagName('script')[0];
        n.parentNode.insertBefore(e, n);
      };
      analytics.SNIPPET_VERSION = '4.0.0';
      analytics.load(process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY); // Replace with your Segment Write Key
      console.log('segment loaded');
    }
  }
})();

// Fetch data and initialize components
const initializeComponents = async () => {
  try {
    register(
      (props) => h(Button, { ...props, onClick: window.openSearchModal }),
      'test-button',
      ['label', 'url', 'onClick', 'disabled'],
      { shadow: true }
    );

    // Register web components
    register((props) => h(SharedHeader, props), 'shared-header', [], {
      shadow: true
    });
    register(SearchModal, 'search-modal', ['isOpen', 'onClose'], { shadow: true });
    register(SearchHit, 'search-hit', ['title', 'category', 'body', 'url', 'image'], {
      shadow: true
    });
    register((props) => h(SharedFooter, { ...props }), 'shared-footer', ['osanoAdded'], {
      shadow: true
    });
    register((props) => h(TenXctaButton, { ...props }), 'ten-x-cta', ['rock', 'variant'], {
      shadow: true
    });

    register(GiveModal, 'give-modal', ['isActive', 'onModalClose'], { shadow: true });

    register(
      (props) => h(Button, { ...props, onClick: window.openGiveModal }),
      'give-modal-button',
      ['label', 'url', 'onClick', 'disabled'],
      { shadow: true }
    );

    register((props) => h(HeaderNotification, props), 'header-notification', ['data', 'user'], {
      shadow: true
    });

    console.log('Components initialized successfully');
  } catch (error) {
    console.error('Error initializing components:', error);
  }
};

initializeComponents();
