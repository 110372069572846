import { h } from 'preact';

import styles from './TenXctaButton.styles.css';
import ShadowWrapper from '../ShadowWrapper/ShadowWrapper.jsx';
import { useEffect, useState } from 'preact/hooks';

const TenXctaButton = ({ rock, variant }) => {
  const [tenXLink, setTenXLink] = useState();
  useEffect(() => {
    const fontsLink = document.querySelector('link[href="https://use.typekit.net/xid0xno.css"]');

    if (!fontsLink) {
      const link = document.createElement('link');
      link.href = 'https://use.typekit.net/xid0xno.css';
      link.rel = 'stylesheet';
      document.head.appendChild(link);

      return () => {
        document.head.removeChild(link);
      };
    }
  }, []);

  useEffect(() => {
    if (!rock) {
      setTenXLink('/10x/dashboard');
      return;
    }

    if (typeof window !== 'undefined') {
      const currentUrl = window.location.href;
      currentUrl.includes('mydev') || currentUrl.includes('rockdev')
        ? setTenXLink('https://demo.crossroads.net/my10x')
        : setTenXLink('https://www.crossroads.net/my10x');
    }
  }, []);
  return (
    <ShadowWrapper styles={styles}>
      <a href={tenXLink}>
        <div
          className={`ten-x-button-container ${
            variant === 'homepage' ? 'homepage' : variant === 'nav' ? 'nav' : ''
          }`}
        >
          <div
            className={`ten-x-button-container-content ${variant === 'nav' ? 'nav-btn-content ' : ''}`}
          >
            {variant === 'homepage' ? (
              <img
                src="https://crds-media.imgix.net/4wsEnpzZAd7i6TxlTy4Q3h/604499da5bf04fdfa736a63a0fc5f8f8/10X_Logo_Basic.png"
                alt="10X Logo"
              />
            ) : null}

            <span className="ten-x-button-text">10X Progress</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M12.175 9H0V7H12.175L6.575 1.4L8 0L16 8L8 16L6.575 14.6L12.175 9Z"
                fill={variant === 'homepage' ? 'white' : variant === 'nav' ? '#ff8200' : ''}
              />
            </svg>
          </div>
        </div>
      </a>
    </ShadowWrapper>
  );
};

export default TenXctaButton;
