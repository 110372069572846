import { h } from 'preact';
import { useState } from 'preact/hooks';
import * as iconData from '../icons.json';
import { marked } from 'marked';

const MySiteMenu = ({ isNavOpen, handleClose, user, serviceTimes, happenings, headerHeight }) => {
  const menuStyle = {
    top: `${headerHeight}px`
  };

  if (!isNavOpen) return null;

  const handleMenuClick = (e) => {
    e.stopPropagation();
  };

  const [expandedStates, setExpandedStates] = useState(new Array(happenings.length).fill(false));

  const getDirectionsUrl = (address) => {
    return encodeURI(`https://www.google.com/maps/dir/?api=1&parameters&destination=${address}`);
  };

  const buildAddress = () => {
    const location = user?.location;
    return (
      <p>
        {location?.Street1}
        <br />
        {location?.Street2}
        <br />
        {location?.City}, {location?.State} {location?.PostalCode}
      </p>
    );
  };

  const getSiteUrl = () => {
    const isDemo = process.env.CRDS_ENV?.includes('demo');
    const baseUrl = isDemo ? 'https://demo.crossroads.net/' : 'https://www.crossroads.net/';
    return baseUrl + user?.campus?.Name.toLowerCase().replace(/\s+/g, '');
  };

  const sanitizeHappeningImageUrl = (url) => {
    if (!url) return url;
    return url.replace('&fit=crop', '');
  };

  const readMore = (content, expanded) => {
    if (!content) {
      return '';
    }

    let htmlContent = marked(content);

    if (expanded) {
      return htmlContent;
    }

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';

    const truncatedText = textContent.slice(0, 90).trim();

    return `<p>${truncatedText}...</p>`;
  };

  const toggleExpanded = (index) => {
    setExpandedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const renderServiceTimes = () => {
    return serviceTimes.map((time, index) => (
      <div key={index}>
        <p>
          <span className="service-times-title">{time.serviceTimeLabel}</span>
          <br />
          {time.serviceTimes.map((serviceTime, idx) => (
            <span key={idx} className="service-times-time">
              {serviceTime}
              <br />
            </span>
          ))}
        </p>
      </div>
    ));
  };

  const renderHappenings = () => {
    return happenings.slice(0, 2).map((happening, index) => {
      const isExpanded = expandedStates[index];

      return (
        <div className="happenings-card" key={index}>
          <a href={happening.linkUrl} className="relative">
            {happening.image.url && (
              <img
                alt={happening.image.title}
                className="img-wrapper"
                src={sanitizeHappeningImageUrl(happening.image.url)}
                width={100}
                height={96}
              />
            )}
          </a>
          <div className="happenings-card-block">
            <h3 className="happenings-card-title">
              <a href={happening.linkUrl}>{happening.title}</a>
            </h3>
            <div
              className={`happenings-card-text ${isExpanded ? 'is-expanded' : 'no-expanded'}`}
              dangerouslySetInnerHTML={{
                __html: readMore(happening.description, isExpanded)
              }}
            />
            <button
              className="read-more"
              onClick={(e) => {
                e.stopPropagation();
                toggleExpanded(index);
              }}
            >
              {isExpanded ? 'Read Less' : 'Read More'}
            </button>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="my-site-nav" onClick={handleMenuClick} style={menuStyle}>
      <div>
        <h2>My Site</h2>
      </div>
      <div className="site-details">
        <div class="my-site-wrapper">
          <h3>{user?.campus?.Name}</h3>
          <div className="my-site-top">
            <div className="my-site-left">
              <div className="my-site-address">{buildAddress()}</div>
              {user?.location?.Street1 && (
                <div>
                  <a
                    class="my-site-directions"
                    href={getDirectionsUrl(user?.location?.Street1)}
                    target="_blank"
                  >
                    Get Directions
                  </a>
                  <p>
                    <i>Not your site?</i>{' '}
                  </p>
                  <p>
                    <a
                      class="preferred-site"
                      href={`${process.env.NEXT_PUBLIC_CRDS_ROCK_DOMAIN}MyAccount`}
                    >
                      <i>Set your preferred site. </i>
                    </a>
                  </p>
                </div>
              )}
            </div>

            <div className="my-site-right">
              {renderServiceTimes()}
              <a href={getSiteUrl()} target="_blank" class="view-campus-link">
                View Campus{' '}
                <svg
                  id="chevron-right-thin"
                  width="9px"
                  height="9px"
                  viewBox="0 0 256 256"
                  preserveAspectRatio="none"
                >
                  <g>
                    <path
                      d="M69.1741071,10.3919643 L65.3860714,14.1794643 C62.8757143,16.6898214 62.8757143,20.7601786 65.3860714,23.2710714 L170.114464,128 L65.3860714,232.728929 C62.8757143,235.239286 62.8757143,239.309643 65.3860714,241.820536 L69.1741071,245.608036 C71.6844643,248.118393 75.7548214,248.118393 78.2651786,245.608036 L191.328214,132.545536 C193.838571,130.035179 193.838571,125.964821 191.328214,123.453929 L78.2651786,10.3919643 C75.7548214,7.88107143 71.6844643,7.88107143 69.1741071,10.3919643 Z"
                      fill="#FF8200"
                    ></path>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>

        {happenings.length > 0 && (
          <div class="my-site-happenings">
            <h4>Happenings at {user?.campus?.Name}</h4>
            {renderHappenings()}
          </div>
        )}
      </div>
      <div className="close-button">
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
          aria-label="Close"
        >
          <div dangerouslySetInnerHTML={{ __html: iconData.close.innerHTML }} />
        </button>
      </div>
    </div>
  );
};

export default MySiteMenu;
