import { h } from 'preact';
import * as iconData from '../icons.json';
import AuthProvider from '../authProvider';
import TenXctaButton from '../../TenXctaButton/TenXctaButton';

const MyAccountMenu = ({ isNavOpen, data, handleClose, user, headerHeight }) => {
  const menuStyle = {
    top: `${headerHeight}px`
  };

  if (!isNavOpen || !data) return null;

  const handleMenuClick = (e) => {
    e.stopPropagation();
  };

  const handleLogoutClick = (e) => {
    e.preventDefault();
    AuthProvider.logout();
  };

  const backgroundImage = user?.user?.Photo?.Url
    ? `linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,0) 100%), url('${user?.user?.Photo?.Url}')`
    : 'black';

  return (
    <div className="my-account-nav" onClick={handleMenuClick} style={menuStyle}>
      <div className="background-overlay" style={{ backgroundImage }} />
      <div className="menu-content">
        <div>
          <h2>{`Hello ${user?.user?.NickName || ''}`}</h2>
        </div>

        <div>
          <ul>
            {data.children &&
              data.children.map((section, index) =>
                Array.isArray(section) ? (
                  section.map((link, idx) => (
                    <li key={`${index}-${idx}`}>
                      {link.title === 'Sign out' ? (
                        <a href="#" onClick={handleLogoutClick}>
                          {link.title}
                        </a>
                      ) : (
                        <a href={link.href}>{link.title}</a>
                      )}
                    </li>
                  ))
                ) : (
                  <li key={index}>
                    <h4>{section}</h4>
                    {section === 'My Account' && (
                      <div className="ten-x-holder">
                        <TenXctaButton variant="nav" />
                      </div>
                    )}
                  </li>
                )
              )}
          </ul>
        </div>
        <div className="close-button">
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            aria-label="Close"
          >
            <div dangerouslySetInnerHTML={{ __html: iconData.close.innerHTML }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyAccountMenu;
